import React from 'react';

import RequiredAsterisk from './RequiredAsterisk';

const InputText = ({
  errors,
  handleBlur,
  handleChange,
  hintText,
  id,
  label,
  name,
  touched,
  value,
  required,
  customClass,
}) => (
  <div
    className={
      'field-group' +
      (errors && touched ? ' error' : '') +
      ' ' +
      (customClass ? customClass : '')
    }
  >
    <label htmlFor={id}>
      {label}
      {required && <RequiredAsterisk />}
    </label>
    {hintText && (
      <p className="hint-text" id={`${id}-hint`}>
        {hintText}
      </p>
    )}
    {errors && touched && <div className="validation-error">{errors}</div>}
    <input
      name={name}
      id={id}
      type="text"
      value={value}
      onChange={handleChange}
      onBlur={handleBlur}
      className={errors && touched ? 'error' : ''}
      aria-describedby={hintText && `${id}-hint`}
    />
  </div>
);

export default InputText;
