import React from 'react';
import { Formik } from 'formik';
import ReCAPTCHA from 'react-google-recaptcha';
import * as Yup from 'yup';
import { navigate } from 'gatsby';

import InputText from './InputText';
import InputTextarea from './InputTextarea';

import RequiredAsterisk from './RequiredAsterisk';

const encode = data => {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
    .join('&');
};

class ReferralForm extends React.Component {
  constructor(props) {
    super(props);
    this.formRef = React.createRef();

    this.state = {};

    this.recaptchaRef = React.createRef();
  }

  render() {
    return (
      <>
        <Formik
          initialValues={{
            name: '',
            postcode: '',
            phoneNumber: '',
            email: '',
            contactHours: '',
            otherDetails: '',
            dateOfBirth: '',
            dayOfBirth: '',
            monthOfBirth: '',
            yearOfBirth: '',
            otherPartyName: '',
            otherPartyDateOfBirth: '',
            otherPartyDayOfBirth: '',
            otherPartyMonthOfBirth: '',
            otherPartyYearOfBirth: '',
            consent: false,
            recaptcha: '',
          }}
          onSubmit={(values, { setSubmitting, resetForm }) => {
            // Concatenate date fields
            values.dateOfBirth = `${values.dayOfBirth}/${values.monthOfBirth}/${
              values.yearOfBirth
            }`;

            values.otherPartyDateOfBirth = `${values.otherPartyDayOfBirth}/${
              values.otherPartyMonthOfBirth
            }/${values.otherPartyYearOfBirth}`;

            fetch(`${process.env.GATSBY_FUNCTIONS_URL}/form-submitted`, {
              method: 'POST',
              mode: 'no-cors',
              headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
              body: encode({ 'form-name': 'Referral form', ...values }),
            })
              // If submission is successful
              // @NOTE fetch API only rejects a promise when a “network error is
              // encountered, although this usually means permissions issues or
              // similar.” — use response.ok to actually check status
              .then(response => {
                setSubmitting(false);
                this.recaptchaRef.current.reset();
                if (!response.ok) {
                  response.json().then(data => {
                    if ('message' in data) {
                      alert(data.message);
                    }
                  });
                } else {
                  resetForm();
                  navigate('/success/');
                }
              })
              .catch(error => alert(error));
          }}
          validationSchema={Yup.object().shape({
            name: Yup.string().required('Enter your name'),
            postcode: Yup.string().required('Enter your postcode'),
            phoneNumber: Yup.string().required('Enter your phone number'),
            email: Yup.string().email(),
            contactHours: Yup.string().required(
              'Enter the times when it is okay to contact you'
            ),
            otherDetails: Yup.string().required(
              'Please provide some details about your situation'
            ),
            dateOfBirth: Yup.string(),
            dayOfBirth: Yup.number()
              .min(1)
              .max(31, 'Must be a valid day'),
            monthOfBirth: Yup.number()
              .min(1)
              .max(12, 'Must be a valid month'),
            yearOfBirth: Yup.number().max(
              new Date().getFullYear() - 1,
              'Must be a year in the past'
            ),
            otherPartyName: Yup.string(),
            otherPartyDateOfBirth: Yup.string(),
            otherPartyDayOfBirth: Yup.number()
              .min(1)
              .max(31, 'Must be a valid day'),
            otherPartyMonthOfBirth: Yup.number()
              .min(1)
              .max(12, 'Must be a valid month'),
            otherPartyYearOfBirth: Yup.number().max(
              new Date().getFullYear() - 1,
              'Must be a year in the past'
            ),
            consent: Yup.boolean()
              .oneOf(
                [true],
                'You must consent to the FLOWS team contacting you'
              )
              .required(),
            recaptcha: Yup.string().required(
              'Please tick the box to verify your submission '
            ),
          })}
        >
          {props => {
            const {
              values,
              touched,
              errors,
              dirty,
              isSubmitting,
              handleChange,
              handleBlur,
              handleSubmit,
              handleReset,
              setFieldValue,
            } = props;
            return (
              <form
                onSubmit={handleSubmit}
                name="Referral form"
                method="post"
                action={`${process.env.GATSBY_FUNCTIONS_URL}/form-submitted`}
                ref={this.formRef}
              >
                <input type="hidden" name="form-name" value="Referral form" />

                {/* Name of person */}
                <InputText
                  errors={errors.name}
                  handleBlur={handleBlur}
                  handleChange={handleChange}
                  id="name"
                  label="Your full name"
                  name="name"
                  hintText="Your own name, that we use to address you"
                  touched={touched.name}
                  value={values.name}
                  required={true}
                />

                {/* Postcode */}
                <InputText
                  errors={errors.postcode}
                  handleBlur={handleBlur}
                  handleChange={handleChange}
                  id="postcode"
                  label="Postcode"
                  name="postcode"
                  hintText="The postcode for your own address"
                  touched={touched.postcode}
                  value={values.postcode}
                  required={true}
                />

                {/* Phone number */}
                <InputText
                  errors={errors.phoneNumber}
                  handleBlur={handleBlur}
                  handleChange={handleChange}
                  id="phoneNumber"
                  label="Phone number"
                  name="phoneNumber"
                  hintText="Your own phone number"
                  touched={touched.phoneNumber}
                  value={values.phoneNumber}
                  required={true}
                />

                {/* Contact hours */}
                <InputText
                  errors={errors.contactHours}
                  handleBlur={handleBlur}
                  handleChange={handleChange}
                  hintText="E.g. Any time / after 2pm only / never by phone"
                  id="contactHours"
                  label="When is it okay to contact you by phone?"
                  name="contactHours"
                  touched={touched.contactHours}
                  value={values.contactHours}
                  required={true}
                />

                {/* Email address */}
                <InputText
                  errors={errors.email}
                  handleBlur={handleBlur}
                  handleChange={handleChange}
                  id="email"
                  label="Email address"
                  name="email"
                  hintText="Your own email address, where we can contact you"
                  touched={touched.email}
                  value={values.email}
                />

                {/* Other details */}
                <InputTextarea
                  errors={errors.otherDetails}
                  handleBlur={handleBlur}
                  handleChange={handleChange}
                  hintText="Please give some brief details of your situation and what you need help with"
                  id="otherDetails"
                  label="Any other details"
                  name="otherDetails"
                  touched={touched.otherDetails}
                  value={values.otherDetails}
                  rows={4}
                  required={true}
                />

                <p className="form-body">
                  If you can give us some more details, it will help us to give
                  you legal advice more quickly. The next questions are optional
                  but it is very helpful if you answer them. All information on
                  this form is completely confidential.
                </p>

                {/* Your DoB */}
                <fieldset className="fieldset u-clearfix">
                  <legend>Your date of birth</legend>
                  <p className="hint-text">
                    Your own date of birth i.e. DD/MM/YYYY
                  </p>
                  <div className="fieldset__flex-container">
                    <InputText
                      errors={errors.dayOfBirth}
                      handleBlur={handleBlur}
                      handleChange={handleChange}
                      id="dayOfBirth"
                      label="Day"
                      name="dayOfBirth"
                      touched={touched.dayOfBirth}
                      value={values.dayOfBirth}
                      customClass="fieldset__input fieldset__input-day"
                    />
                    <InputText
                      errors={errors.monthOfBirth}
                      handleBlur={handleBlur}
                      handleChange={handleChange}
                      id="monthOfBirth"
                      label="Month"
                      name="monthOfBirth"
                      touched={touched.monthOfBirth}
                      value={values.monthOfBirth}
                      customClass="fieldset__input fieldset__input-month"
                    />
                    <InputText
                      errors={errors.yearOfBirth}
                      handleBlur={handleBlur}
                      handleChange={handleChange}
                      id="yearOfBirth"
                      label="Year"
                      name="yearOfBirth"
                      touched={touched.yearOfBirth}
                      value={values.yearOfBirth}
                      customClass="fieldset__input fieldset__input-year"
                    />
                  </div>
                </fieldset>

                {/* Name of other party */}
                <InputText
                  errors={errors.otherPartyName}
                  handleBlur={handleBlur}
                  handleChange={handleChange}
                  hintText="This is the person whose actions you are concerned about or who you need protection from."
                  id="otherPartyName"
                  label="Name of other party"
                  name="otherPartyName"
                  touched={touched.otherPartyName}
                  value={values.otherPartyName}
                />

                {/* Other party DoB */}
                <fieldset className="fieldset u-clearfix">
                  <legend>Date of birth of other party</legend>
                  <p className="hint-text">DD/MM/YYYY</p>
                  <div className="fieldset__flex-container">
                    <InputText
                      errors={errors.otherPartyDayOfBirth}
                      handleBlur={handleBlur}
                      handleChange={handleChange}
                      id="otherPartyDayOfBirth"
                      label="Day"
                      name="otherPartyDayOfBirth"
                      touched={touched.otherPartyDayOfBirth}
                      value={values.otherPartyDayOfBirth}
                      customClass="fieldset__input fieldset__input-day"
                    />
                    <InputText
                      errors={errors.otherPartyMonthOfBirth}
                      handleBlur={handleBlur}
                      handleChange={handleChange}
                      id="otherPartyMonthOfBirth"
                      label="Month"
                      name="otherPartyMonthOfBirth"
                      touched={touched.otherPartyMonthOfBirth}
                      value={values.otherPartyMonthOfBirth}
                      customClass="fieldset__input fieldset__input-month"
                    />
                    <InputText
                      errors={errors.otherPartyYearOfBirth}
                      handleBlur={handleBlur}
                      handleChange={handleChange}
                      id="otherPartyYearOfBirth"
                      label="Year"
                      name="otherPartyYearOfBirth"
                      touched={touched.otherPartyYearOfBirth}
                      value={values.otherPartyYearOfBirth}
                      customClass="fieldset__input fieldset__input-year"
                    />
                  </div>
                </fieldset>

                {/* Consent checkbox */}
                <div
                  className={
                    'field-group' +
                    (errors.consent && touched.consent ? ' error' : '')
                  }
                >
                  {errors.consent && touched.consent && (
                    <div className="validation-error">{errors.consent}</div>
                  )}
                  <label htmlFor="consent">
                    <input
                      name="consent"
                      id="consent"
                      type="checkbox"
                      checked={values.consent}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      className={
                        errors.consent && touched.consent ? 'error' : ''
                      }
                    />
                    I consent to the FLOWS team contacting me
                    <RequiredAsterisk />
                  </label>
                </div>

                {/* ReCAPTCHA */}
                <div
                  className={
                    'u-margin-top-full field-group' +
                    (errors.recaptcha && touched.recaptcha ? ' error' : '')
                  }
                >
                  {errors.recaptcha && touched.recaptcha && (
                    <div className="validation-error">{errors.recaptcha}</div>
                  )}
                  <ReCAPTCHA
                    sitekey={process.env.GATSBY_RECAPTCHA_SITE_KEY}
                    onChange={value => {
                      if (value) {
                        setFieldValue('recaptcha', value);
                      } else {
                        setFieldValue('recaptcha', '');
                      }
                    }}
                    ref={this.recaptchaRef}
                  />
                </div>
                <p className="u-margin-top-full">
                  Please check this form once again before you submit it. Please
                  ensure that you have entered the correct details and that the
                  contact details are yours. We will be using the details and
                  information provided to contact you to provide further
                  assistance, it is therefore essential that these details are
                  correct and are also safe forms of contact.
                </p>
                <button
                  type="submit"
                  disabled={isSubmitting}
                  className="u-margin-top-full"
                >
                  Submit
                </button>
              </form>
            );
          }}
        </Formik>
      </>
    );
  }
}

export default ReferralForm;
