import React from 'react';

import Layout from '../components/Layout';
import SEO from '../components/SEO';
import ReferralForm from '../components/ReferralForm';

class IndexPage extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <Layout>
        <SEO title="Home" />
        <div className="container">
          <div className="grid justify-center">
            <div className="col-12 col-md-8 col-xlg-6">
              <h1>FLOWS self referral</h1>
              <p className="u-margin-top-full u-font-bold u-font-normal u-text-blue-800">
                If you have been experiencing domestic abuse of any kind, we can
                help you access legal options to keep you safe. We can also help
                you find other specialist support.
              </p>
              <p className="u-margin-top-full">
                Please use this form to send us your details securely. We will
                contact you within one working day. This form is entirely
                confidential, we will never share your details unless you ask us
                to.
              </p>
              <ReferralForm />
              <div className="u-margin-top-full" aria-hidden="true">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 128 128"
                  className="svg-icon red"
                >
                  <path d="M110.1 16.4L75.8 56.8l.3 1 50.6-10.2v32.2l-50.9-8.9-.3 1 34.7 39.1-28.3 16.5-18.2-49.3-.7.3-18.5 49L17.2 111l34.1-39.8v-.6l-50 9.2V47.6l49.3 9.9.3-.6-33.7-40.2L45.5.5l17.8 48.7h.7L82.1.5l28 15.9z" />
                </svg>
                &nbsp;— required field
                <p className="u-margin-top-full">
                  Please use this form to send us your details securely. We will
                  contact you within one working day. This form is entirely
                  confidential, we will never share your details unless you ask
                  us to.
                </p>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    );
  }
}

export default IndexPage;
